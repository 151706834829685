import 'babel-polyfill';
import $ from 'jquery';
import 'bootstrap';

import WindowManager from './modules/wrapper/WindowManager';
import Header from './modules/wrapper/Header';
import Footer from './modules/wrapper/Footer';
import Images from './modules/wrapper/Images';
import FocusStates from './modules/wrapper/FocusStates';
import Main from './modules/wrapper/Main';
import * as events from './modules/events';

$(() => {

  let windowManager = new WindowManager();
  let main = new Main();
  let header = new Header();
  let footer = new Footer();
  let images = new Images();
  let focusstates = new FocusStates();

  windowManager.on(events.WINDOW_RESIZED, (event) => { onWindowResized(event); } );
  windowManager.on(events.WINDOW_SCROLLED, (event) => { onWindowScrolled(event); });
  windowManager.on(events.GTM_LOADED, () => { onGtmLoaded(); });
  windowManager.on(events.USER_STARTED_TABBING, () => { onUserStartedTabbing(); });
  windowManager.on(events.USER_STOPPED_TABBING, () => { onUserStoppedTabbing(); });

  header.on(events.HEADER_SIZE_CHANGE, (event) => { onHeaderSizeChange(event); } )

  function onWindowResized(event) {
    header.onWindowResize(event);
    main.onWindowResize(event);
    footer.onWindowResize(event);
  }

  function onWindowScrolled(event) {
    if(header) { header.onWindowScrolled(event); }
  }

  function onHeaderSizeChange(event) {
    main.onHeaderSizeChange(event.height);
  }

  function onGtmLoaded() {
    images.onGtmLoaded();
  }

  function onUserStartedTabbing(event) {
    focusstates.handleFirstTab(event);
    header.setNavigationDevice(false);
  }

  function onUserStoppedTabbing(event) {
    focusstates.handleMouseDownOnce(event);
    header.setNavigationDevice(true);
  }

});