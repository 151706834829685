import $ from 'jquery';
import 'jquery.easing';

export default class Footer {

  constructor() {

    // initial divider setting
    this.setMenuDividers($('#menu-footer > li'));
    this.setMenuDividers($('#menu-footer-external-links > li'));
  }

  setMenuDividers($items) {

    var left = 0;
    $items.each(function (index) {
      if (index !== 0) {
        if ($(this).offset().left > left) {
          // list item has siblings to the left
          if (!$(this).hasClass('menu-divider')) {
            $(this).addClass('menu-divider')
          }
        } else {
          if ($(this).hasClass('menu-divider')) {
            $(this).removeClass('menu-divider')
          }
        }
      }

      left = $(this).offset().left;

    })
  }

  onWindowResize() {

    // update footer
    this.setMenuDividers($('#menu-footer > li'));
    this.setMenuDividers($('#menu-footer-external-links > li'));
  }

}
