import $ from 'jquery';
import EventEmitter from 'eventemitter3';
import { Collapse, Dropdown } from 'bootstrap';

import * as events from '../events';

export default class Header extends EventEmitter {

  constructor() {
    super();
    this.headerEl = $('#mainnav');
    this.accordionChangeSet = [];
    this.resizeTimeout;
    this.dropdowns;

    $('#navbarMainCollapsible')[0].addEventListener('shown.bs.collapse', () => { this.onNavOpened() } );
    $('#navbarMainCollapsible')[0].addEventListener('hide.bs.collapse', () => { this.onNavClose() } );
    $('.menu-secondary li.dropdown').on('mouseenter', (event) => { this.onSecondaryOver(event); });
    $('.menu-secondary li.dropdown').on('mouseleave', (event) => { this.onSecondaryOut(event); })

    this.collapse_navbarMainCollapsible = new Collapse(document.getElementById('navbarMainCollapsible'), {toggle: false});

    var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
    this.dropdowns = dropdownElementList.map( (dropdownToggleEl) => new Dropdown(dropdownToggleEl))

    $('#navbarMainCollapsible, #navbarMainCollapsible .accordion-collapse').each( (index, el) => {
      $(el)[0].addEventListener('show.bs.collapse', (event) => { this.onAccordionChange(event) } );
      $(el)[0].addEventListener('hide.bs.collapse', (event) => { this.onAccordionChange(event) } );
    });

    this.setNavigationDevice(true);
  }

  setNavigationDevice(mouse_navigating) {
    // if(mouse_navigating) {
    //   $('.menu-secondary li.dropdown button.dropdown-toggle').on('click', (event) => { this.onDropdownToggleClick(event) } );
    // } else {
    //   $('.menu-secondary li.dropdown button.dropdown-toggle').off('click' );
    // }
  }

  getHeight() {
    return this.headerEl.outerHeight();
  }

  toggleShowState($targets) {
    $.each($targets, (index, target) => {
      if($(target).hasClass('show')) {
        $(target).removeClass('show');
      } else {
        $(target).addClass('show');
      }
    })
  }

  onDropdownToggleClick(event) {
    // put logic in here to click user through
    window.location.href = $(event.currentTarget).data('url');
  }

  onNavClose() {
    $('#mainnav').removeClass('opened');
  }

  onNavOpened() {
    $('#mainnav').addClass('opened');
  }

  onAccordionChange(event) {
    clearTimeout(this.resizeTimeout);

    // add element to the changeset array so that all height changes are accounted for together
    if($.inArray(event.target, this.accordionChangeSet) < 0) { this.accordionChangeSet.push(event.target); }

    // change the state of all accordions in the change set to calculate height
    this.toggleShowState(this.accordionChangeSet);

    const height = this.getHeight();

    // change everything back to their original states for bootstrap animation
    this.toggleShowState(this.accordionChangeSet);

    // set a timeout so that all the calculations are performed as one before alerting anything
    this.resizeTimeout = setTimeout(() => {
      this.emit(events.HEADER_SIZE_CHANGE, { height });
      this.accordionChangeSet = [];
    }, 10);


  }

  onSecondaryOver(event) {
    const dropdown = $(event.currentTarget).find('.dropdown-toggle')[0];
    const instance = Dropdown.getInstance(dropdown);

    if(null !== instance) {
      instance.show();
    }
  }

  onSecondaryOut(event) {
    const dropdown = $(event.currentTarget).find('.dropdown-toggle')[0];
    const instance = Dropdown.getInstance(dropdown);

    if(null !== instance) {
      instance.hide();
    }
  }

  onWindowScrolled() {
    this.collapse_navbarMainCollapsible.hide();
  }

  onWindowResize() {
    this.collapse_navbarMainCollapsible.hide();
  }
}
