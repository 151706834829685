import $ from 'jquery';

export default class Main {

  constructor() {
    this.headerEl = $('#mainnav');
    this.mainEl = $('#main');

    this.setMainPadding();
  }

  setMainPadding(height) {

    // need an extra pixel for the mainnav bottom border in desktop that is included with the calculation in mobile
    //let offset = window.matchMedia('(min-width: ' + BREAKPOINT_LG + 'px)').matches ? 1 : 0;
    //this.mainEl.css('padding-top', this.headerEl.outerHeight() + offset);

    if(height) {
      this.mainEl.css('padding-top', height);
    } else {
      this.mainEl.css('padding-top', this.headerEl.outerHeight());
    }

  }

  onHeaderSizeChange(height = null) {
    this.setMainPadding(height);
  }

  onWindowResize() {
    this.setMainPadding();
  }
}

