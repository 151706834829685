import $ from 'jquery';
import EventEmitter from 'eventemitter3';
import { debounce } from 'debounce';

import * as events from '../events';


export default class WindowManager extends EventEmitter {

  constructor() {
    super();
    $(window).on('resize orientationchange', debounce(() => { this.onWindowResize() }, 50));
    $(window).on('gtm_loaded', () => { this.onGtmLoaded() } );
    $(window).on('scroll', () => { this.onWindowScroll() });
    $(window).on('keydown', (event) => {this.onKeyDown(event)});

    this.width = $(window).width();
  }

  onFirstMouseDown() {
    $(window).off('mousedown');
    $(window).on('keydown', (event) => { this.onKeyDown(event); } );

    this.emit(events.USER_STOPPED_TABBING, {});
  }

  onKeyDown(event) {
    if (event.keyCode === 9) { // the 'I am a keyboard user' key
      $(window).off('keydown');
      $(window).on('mousedown', () => { this.onFirstMouseDown(); });

      this.emit(events.USER_STARTED_TABBING, {});
    }
  }

  onWindowResize() {
    if (this.width !== $(window).width()) {
      // iOS safari has an interesting bug that causes the resize event to fire every time the shows/hides
      this.width = $(window).width()
      this.emit(events.WINDOW_RESIZED, {});
    }
  }

  onGtmLoaded() {
    this.emit(events.GTM_LOADED, {});
    $(window).off('gtm_loaded');
  }

  onWindowScroll() {
    this.emit(events.WINDOW_SCROLLED, {});
  }

}