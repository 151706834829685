import $ from 'jquery';

export default class FocusStates {

  constructor() {}

  handleFirstTab() {
    $('body').addClass('user-is-tabbing');
    $('#navbarMainCollapsible')[0].addEventListener('shown.bs.collapse', () => { this.onNavOpened() } );
    $('#navbarMainCollapsible')[0].addEventListener('hide.bs.collapse', () => { this.onNavClosed() } );
  }

  handleMouseDownOnce() {
    $('body').removeClass('user-is-tabbing');
    $('#navbarMainCollapsible')[0].removeEventListener('shown.bs.collapse', () => { this.onNavOpened() } );
    $('#navbarMainCollapsible')[0].removeEventListener('hide.bs.collapse', () => { this.onNavClosed() } );
  }

  onNavOpened() {
    // set focus on first element in navigation
    //$('#navbarMainCollapsible').find('li a:first')[0].focus();

    // set listener to keep tabbed user looping through the menu
    $('#navbarMainCollapsible ul li a').last().on('keydown', this.onLastNavElementTabbed );

    // set backwards listener from navbar toggler
    $('#mainnav .navbar-toggler').on('keydown', this.onTogglerTabbed );

    //set backwards listener from first menu item
    $('#navbarMainCollapsible').find('li a:first').on('keydown', this.onFirstNavElementTabbed );
  }

  onNavClosed() {
    $('#navbarMainCollapsible ul li a').last().off('keydown', this.onLastNavElementTabbed );
    $('#mainnav .navbar-toggler').off('keydown', this.onTogglerTabbed );
    $('#navbarMainCollapsible').find('li a:first').off('keydown', this.onFirstNavElementTabbed );
  }

  onTogglerTabbed(event) {
      if (event.keyCode === 9) {
        if (event.shiftKey && $('#navbarMainCollapsible ul li a').is(':visible')) {
          return $('#navbarMainCollapsible ul li a').last()[0].focus(), !1
        } else {
          return $('#navbarMainCollapsible').find('li a:first')[0].focus(), !1
        }
      }
  }

  onFirstNavElementTabbed(event) {
    if ($(window).width() < 992 && event.keyCode === 9) {
      if (event.shiftKey) {
        return $('#mainnav .navbar-toggler')[0].focus(), !1
      }
    }
  }

  onLastNavElementTabbed(event) {
    if ($(window).width() < 992 && event.keyCode === 9) {
      if (event.shiftKey) {
        return $(event.target).closest('li').prev('li').find('a')[0].focus(), !1
      } else {
        return $('#mainnav .navbar-toggler')[0].focus(), !1
      }
    }
  }
}