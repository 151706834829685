import $ from 'jquery';

export default class Images {

  constructor() {
    // run the event if it loads before the page has fully loaded
    this.onGtmLoaded();
  }

  onGtmLoaded() {
    setTimeout(function() {
      $('img').each(function() {
        if(!this.hasAttribute('alt')) {
          $(this).attr('alt', '');
          $(this).attr('aria', 'hidden');
          $(this).attr('role', 'presentation');
        }
      })
    }, 500);
  }
}
